import { request } from "../utils/axios";
import { setAuthToken } from "../config/helpers";
import { toast } from "react-toastify";

export const fetchUserData = async () => {
	try {
		const res = await request.get("api/trips/user/");
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		throw err;
	}
};

export const fetchCompanyData = async () => {
	try {
		const res = await request.get("api/trips/company/");
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		throw err;
	}
};

export const fetchBalance = async () => {
	try {
		const res = await request.get("api/finance/balance/");
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		throw err;
	}
};

export const updatePersonalProfile = async (values) => {
	try {
		const res = await request.patch("api/trips/user/5/", values);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		for (let i in err?.response?.data) {
			toast.error(err?.response?.data[i][0]);
		}
		toast.error(err?.response?.data?.message);
		throw err;
	}
};

export const changePassword = async (values) => {
	try {
		const res = await request.post("auth/password/change/", values);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		for (let i in err?.response?.data) {
			toast.error(err?.response?.data[i][0]);
		}
		throw err;
	}
};

export const createCompany = async () => {
	try {
		const res = await request.post("api/trips/company/", {
			logo: "image/upload/v1642815358/o5vsoa4j5gqnwebjvzue.png",
			business_name: "Tav Insurance",
			business_bio: "Some long crazy text",
			business_industry: "Insurance",
			business_address: "some address in some place",
			business_city: "some city",
			business_state: "Lagos",
			business_country: "Nigeria",
		});
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		for (let i in err?.response?.data) {
			toast.error(err?.response?.data[i][0]);
		}
		throw err;
	}
};

export const updateCompany = async (values, id) => {
	try {
		const res = await request.patch(`api/trips/company/${id}/`, values);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		// for (let i in err?.response?.data) {
		// 	toast.error(err?.response?.data[i][0]);
		// }
		throw err;
	}
};
