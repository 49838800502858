import React, {useEffect, useState} from "react";
import "./style.scss";
import flight from "../../resource/img/flight.svg";
import car from "../../resource/img/car.svg";
import hotel from "../../resource/img/hotel.svg";
import event from "../../resource/img/event.svg";
import bonus from "../../resource/img/bonus.svg";
import total from "../../resource/img/total.svg";
import wallet from "../../resource/img/wallet.svg";
import cash from "../../resource/img/cash.svg";
import topup from "../../resource/img/Topup.svg";
// import useAxios from "../../hooks/dist/useAxios";
// import useAxios from "axios-hooks";
import {useNavigate} from "react-router-dom";
import AliceCarousel from "react-alice-carousel";
import {BsFillPlusCircleFill, BsChatRightFill} from "react-icons/bs";
import Button from "../../components/Button";
import ModalComponent from "../../components/Modal";
import MUITable from "../../components/MUITable";
import {
    morphOnHoldData,
    morphSearchHistory,
    OnHoldDataToArr,
    prettifyMoney,
    extractAirlineCode,
} from "../../helpers/helperFuncs";
import MUIModal from "../../components/MUIModal";
import {
    HeadsUpModal,
    ConvertPointsModal,
    FundAccountModal,
    ReferralModal,
    CreditModal,
    // CreditFacilityModal,
} from "../../modals";
// import { SearchHistoryModel } from "./data";
import CustomTooltip from "../../components/CustomTooltip";
import {useTripStore} from "../../zustand/store";
import {
    fetchSearchHistory,
    fetchOnHoldData,
    fetchTransactionSummary,
} from "../../requests/dash";
import {fetchBalance} from "../../requests";
import HeadwayWidget from "../../components/HeadwayWidget";

export default function Dashboard() {
    interface OnHold {
        kardinal_referenceerence: string;
        destination: string;
        departure: string;
        departure_date: string;
        passenger: string;
    }

    interface Summary {
        total_spend: number;
        travel_spend: number;
        car_spend: number;
        hotel_spend: number;
        bonus_spend: number;
        flights: number;
        event_spend: number;
        events: number;
        hotels: number;
        cars: number;
    }

    const bigFlightData = useTripStore((state: any) => state.bigFlightData);
    const updateBigFlightData = useTripStore(
        (state: any) => state.updateBigFlightData
    );
    const globalAirports = useTripStore((state: any) => state.globalAirports);

    let navigate = useNavigate();

    let [balanceLoading, setBalanceLoading] = useState(false);
    let [refetch, setRefetch] = useState(false);
    let [creditBalance, setCreditBalance] = useState(0);
    let [wallettBalance, setWalletBalance] = useState(0);
    let [pointBalance, setPointBalance] = useState(0);
    let [searchHistory, setSearchHistory] = useState([]);
    let [onHoldData, setOnHoldData] = useState<OnHold[]>([]);
    let [summary, setSummary] = useState<Summary[]>([]);
    const [openCreditModal, setOpenCreditModal] = useState(false);
    const [openHeadsup, setOpenHeadsup] = useState(false);
    const [openFundAccount, setOpenFundAccount] = useState(false);
    const [openFundPop, setOpenFundPop] = useState(false);
    const [openConvertPoints, setOpenConvertPoints] = useState(false);
    const [openReferralModal, setOpenReferralModal] = useState(false);
    const [tempBigFlightData, setTempBigFlightData] =
        useState<any>(bigFlightData);

    const directToTrips = () => {
        navigate("/trips");
    };

    const middleData = [
        {
            label: "Flights",
            icon: flight,
            count: "flights",
            amount: "travel_spend",
        },
        {label: "Hotels", icon: hotel, count: "hotels", amount: "hotel_spend"},
        {label: "Cars", icon: car, count: "cars", amount: "car_spend"},
        {label: "Events", icon: event, count: "events", amount: "event_spend"},
    ];

    // GET CREDIT BALANCE

    useEffect(() => {
        const fetchFunc = async () => {
            try {
                setBalanceLoading(true);
                const res = await fetchBalance();
                console.log("fetchBalance", res);
                setCreditBalance(res?.data?.data["credit"]);
                setWalletBalance(res?.data?.data["wallet"]);
                setPointBalance(res?.data?.data["bonus"]);
            } catch (e) {
                console.log(e);
            } finally {
                setBalanceLoading(false);
            }
        };
        fetchFunc();
    }, [refetch]);

    // GET SEARCH HISTORY
    const getSearch = async () => {
        try {
            const res = await fetchSearchHistory();
            console.log("searchstuff", res?.data);
            setSearchHistory(res?.data?.data);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getSearch();
    }, []);

    // GET ONHOLD DATA
    const getOnhold = async () => {
        try {
            const res = await fetchOnHoldData();
            console.log("onholdstuff", res?.data);
            setOnHoldData(res?.data?.data);
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        getOnhold();
    }, []);

    // GET CARD SUMMARY
    useEffect(() => {
        const fetchFunc = async () => {
            try {
                const res = await fetchTransactionSummary();
                console.log("fetchBalance", res);
                setSummary(res.data?.data);
            } catch (e) {
                console.log(e);
            }
        };
        fetchFunc();
    }, []);

    const updateTemp = (currData: any, uniqueOutbound: any) => {
        console.log("uniqueOutbound", uniqueOutbound);
        setTempBigFlightData({
            selectedTraveller: currData?.array[0]?.travelers,
            departure: currData?.array[0]?.flight?.origin,
            destination: currData?.array[0]?.flight?.destination,
            departure_date: currData?.array[0]?.flight?.travel_date,
            return_date:
                currData?.array[0]?.trip_type === "return"
                    ? currData?.array[currData?.array?.length - 1]?.flight?.return_date
                    : null,
            cabin: currData?.array[0]?.flight?.cabin_type,
            showTripType:
                currData?.array[0]?.trip_type === "return" ? "Roundtrip" : "One way",
            selectedDeparture: {
                outbound: uniqueOutbound?.filter(
                    (item: any) => item?.flight_type === "departing_flight"
                ),
                outbound_return: uniqueOutbound?.filter(
                    (item: any) => item?.flight_type === "return_flight"
                ),
                total_outbound_duration:
                    currData?.array[0]?.booking_category === "domestic"
                        ? uniqueOutbound
                            ?.filter(
                                (item: any) => item?.flight_type === "departing_flight"
                            )
                            .reduce(
                                (partialSum: number, a: any) => partialSum + a?.duration,
                                0
                            )
                        : uniqueOutbound?.filter(
                            (item: any) => item?.flight_type === "departing_flight"
                        )[0]?.duration,
                total_outbound_duration_return:
                    currData?.array[0]?.booking_category === "domestic"
                        ? uniqueOutbound
                            ?.filter((item: any) => item?.flight_type === "return_flight")
                            .reduce(
                                (partialSum: number, a: any) => partialSum + a?.duration,
                                0
                            )
                        : uniqueOutbound?.filter(
                            (item: any) => item?.flight_type === "return_flight"
                        )[0]?.duration,
                amount: uniqueOutbound
                    ?.filter((item: any) => item?.flight_type === "departing_flight")
                    .reduce(
                        (partialSum: number, a: any) => partialSum + a?.flight_price,
                        0
                    ),
                tax: uniqueOutbound
                    ?.filter((item: any) => item?.flight_type === "departing_flight")
                    .reduce((partialSum: number, a: any) => partialSum + a?.tax, 0),
            },

            selectedArrival:
                currData?.array[0]?.trip_type === "return"
                    ? {
                        outbound:
                            currData?.array[0]?.booking_category === "domestic"
                                ? uniqueOutbound?.filter(
                                    (item: any) => item?.flight_type === "return_flight"
                                )
                                : uniqueOutbound?.filter(
                                    (item: any) => item?.flight_type === "departing_flight"
                                ),
                        outbound_return: uniqueOutbound?.filter(
                            (item: any) => item?.flight_type === "return_flight"
                        ),
                        total_outbound_duration: uniqueOutbound
                            ?.filter((item: any) => item?.flight_type === "return_flight")
                            .reduce(
                                (partialSum: number, a: any) => partialSum + a?.duration,
                                0
                            ),
                        // total_outbound_duration
                        amount: uniqueOutbound
                            ?.filter((item: any) => item?.flight_type === "return_flight")
                            .reduce(
                                (partialSum: number, a: any) => partialSum + a?.flight_price,
                                0
                            ),
                        tax: uniqueOutbound
                            ?.filter((item: any) => item?.flight_type === "return_flight")
                            .reduce((partialSum: number, a: any) => partialSum + a?.tax, 0),
                    }
                    : undefined,
            flightSelected: true,
            bookingConfirmed: true,
            paymentConfirmed: false,
            departureCity: globalAirports?.filter(
                (item: any) => item.iata_code === currData?.array[0]?.flight?.origin
            ),
            arrivalCity: globalAirports?.filter(
                (item: any) =>
                    item.iata_code === currData?.array[0]?.flight?.destination
            ),
            flightReference: currData?.kardinal_reference,
            route: currData?.array[0]?.booking_category,
            // exchange_rate: 0,
            booking_deadline: currData?.array[0]?.booking_deadline,
        });
    };

    // Get User
    const userData = useTripStore((state: any) => state.userData);

    console.log(userData);
    console.log("bigFlightData", bigFlightData);

    const sliderList = [
        {
            caption: "Low balance for your trip?",
            desc: "Always be on the move. Apply for credit to help finance your trip",
            image: "",
            button: {
                label: "Apply",
                color: "#1921d5",
                action: () => {
                    setOpenCreditModal(true);
                },
            },
        },
        {
            caption: "Increase team productivity",
            desc: "Experience specially curated events designed to increase employee engagement",
            image: "",
            button: {
                label: "Explore events", color: "#40798c", action: () => {
                }
            },
        },
        {
            caption: "Earn points for your trip",
            desc: "Every ticket you buy earns you points. Accumulated points can pay for future trips",
            image: "",
            button: {
                label: "Fly now", color: "#8e8532", action: () => {
                }
            },
        },
    ];
    console.log("summary", summary);

    const sliderItems = sliderList.map((slide) => (
        <div className="slider">
            <div className="slider-text-wrapper">
                <div className="slider-text">
                    <h2>{slide.caption}</h2>
                    <p>{slide.desc}</p>
                    <Button
                        className="pry"
                        style={{backgroundColor: slide.button.color}}
                        onClick={slide.button.action}
                    >
                        {slide.button.label}
                    </Button>
                </div>
            </div>
        </div>
    ));

    console.log(summary);

    return (
        <div className="dashboard">
            <p className="first mb-3">
                Welcome{" "}
                {userData?.username
                    ? userData?.username[0].toUpperCase() + userData?.username.slice(1)
                    : ""}
            </p>
            <div className="dash-top">
                <div className="slider-wrapper">
                    <AliceCarousel
                        mouseTracking
                        items={sliderItems}
                        autoPlay={true}
                        infinite={true}
                        autoPlayInterval={7000}
                    />
                </div>
                <div className="funding-new">
                    <ModalComponent
                        type="fund_wallet"
                        email={userData?.email}
                        refetch={refetch}
                        setRefetch={setRefetch}
                    />
                    <div className="fund-body">
                        <div className="fund-line">
                            <div className="img-container">
                                <img src={cash} alt="flight"/>
                                <p>Credit Balance</p>
                            </div>
                            <p>₦{prettifyMoney(creditBalance)}</p>
                        </div>
                        <div className="fund-line">
                            <div className="img-container">
                                <img src={wallet} alt="flight"/>
                                <p>Wallet Balance</p>
                            </div>
                            <p>₦{prettifyMoney(wallettBalance)}</p>
                        </div>
                        <div className="fund-line">
                            <div className="img-container">
                                <img src={total} alt="flight"/>
                                <p>Total Balance</p>
                            </div>
                            <p>₦{prettifyMoney(creditBalance + wallettBalance)}</p>
                        </div>
                        <div
                            className="fund-line fund-line--active"
                            onClick={() => setOpenConvertPoints(true)}
                        >
                            <div className="img-container">
                                <img src={bonus} alt="flight"/>
                                <div>
                                    Bonus Points
                                    <span
                                        style={{
                                            color: "rgba(101, 100, 95, 0.44)",
                                            cursor: "pointer",
                                            fontSize: "13px",
                                            marginLeft: "0.5rem",
                                        }}
                                        className="q-mark"
                                    >
										?
										<CustomTooltip>
											Bonus points can be converted to cash when it reaches
											1500. 10 points equals 100 naira
										</CustomTooltip>
									</span>
                                </div>
                            </div>
                            <p>{pointBalance}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="middle-wrapper">
                <p className="first mb-3">
                    <span>Trip Summary</span>
                    <span className="link" onClick={() => setOpenReferralModal(true)}>
						Get cashback
					</span>
                </p>

                {/* this will not work, the APi is returning one object not a array that can be mapped */}
                {summary.map((item: any, ind: number) => {
                    console.log("item", item);
                    return (
                        <div className="middle" key={ind}>
                            {middleData.map((middleItem, midInd) => (
                                <MiddleCard middleItem={middleItem} item={item} key={midInd}/>
                            ))}
                        </div>
                    );
                })}
            </div>

            <div className="bottom">
                <div className="on-hold">
                    <div className="on-hold-top">
                        <p className="first">Bookings on hold</p>
                    </div>

                    <div className="table-desktop">
                        <MUITable
                            headers={[
                                {label: "PNR", key: "kardinal_referenceerence"},
                                {label: "Name", key: "passenger"},
                                {label: "Destination", key: "destination"},
                                {label: "Deadline", key: "deadline"}, //this needs to be 1 day not sure how to pass it into the body
                                {label: "", key: "status"},
                            ]}
                            bodyData={morphOnHoldData(onHoldData)}
                            specialStyles={[
                                {
                                    key: "status",
                                    styles: {
                                        color: "#40798C",
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                    },
                                },
                            ]}
                            specialActions={[
                                {
                                    key: "status",
                                    action: (ind: number) => {
                                        setOpenHeadsup(true);
                                        // console.log("ind: number", ind);
                                        const currData = OnHoldDataToArr(onHoldData)[ind];
                                        const uniqueOutbound = [
                                            ...new Map(
                                                currData?.array
                                                    ?.map((item: any) => ({
                                                        ...item?.flight,
                                                        airline_details: {
                                                            code: extractAirlineCode(
                                                                item?.flight?.airline_logo
                                                            ),
                                                            logo: item?.flight?.airline_logo,
                                                            name: item?.flight?.airline_name,
                                                        },
                                                        airport_from: item?.flight?.origin,
                                                        airport_to: item?.flight?.destination,
                                                        departure_time: item?.flight?.travel_date,
                                                        arrival_time: item?.flight?.return_date,
                                                        duration:
                                                            item?.booking_category === "domestic"
                                                                ? Number(item?.flight?.flight_duration)
                                                                : item?.flight?.flight_duration,
                                                        price: item?.price,
                                                        tax: item?.tax ?? 0,
                                                    }))
                                                    .map((v: any) => [v.airline_number, v])
                                            ).values(),
                                        ];
                                        console.log("uniqueOutbound", uniqueOutbound);
                                        console.log("sksks", morphOnHoldData(onHoldData)[ind]);
                                        console.log("liessss", OnHoldDataToArr(onHoldData));
                                        console.log("currData", currData);

                                        updateTemp(currData, uniqueOutbound);
                                    },
                                },
                            ]}
                            emptyState={{
                                message: "No booking found on hold",
                                button: {
                                    label: "Start New Trip",
                                    action: directToTrips,
                                },
                            }}
                        />
                    </div>
                    <div className="table-mobile">
                        <MUITable
                            headers={[
                                {label: "Name", key: "passenger"},
                                {label: "Destination", key: "destination"},
                                {label: "Deadline", key: "deadline"},
                                {label: "", key: "status"},
                            ]}
                            bodyData={morphOnHoldData(onHoldData)}
                            specialStyles={[
                                {
                                    key: "status",
                                    styles: {
                                        color: "#40798C",
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                    },
                                },
                            ]}
                            specialActions={[
                                {
                                    key: "status",
                                    action: (ind: number) => {
                                        setOpenHeadsup(true);
                                        // console.log("ind: number", ind);
                                        const currData = OnHoldDataToArr(onHoldData)[ind];
                                        const uniqueOutbound = [
                                            ...new Map(
                                                currData?.array
                                                    ?.map((item: any) => ({
                                                        ...item?.flight,
                                                        airline_details: {
                                                            code: extractAirlineCode(
                                                                item?.flight?.airline_logo
                                                            ),
                                                            logo: item?.flight?.airline_logo,
                                                            name: item?.flight?.airline_name,
                                                        },
                                                        airport_from: item?.flight?.origin,
                                                        airport_to: item?.flight?.destination,
                                                        departure_time: item?.flight?.travel_date,
                                                        arrival_time: item?.flight?.return_date,
                                                        duration:
                                                            item?.booking_category === "domestic"
                                                                ? Number(item?.flight?.flight_duration)
                                                                : item?.flight?.flight_duration,
                                                        price: item?.price,
                                                        tax: item?.tax ?? 0,
                                                    }))
                                                    .map((v: any) => [v.airline_number, v])
                                            ).values(),
                                        ];
                                        console.log("uniqueOutbound", uniqueOutbound);
                                        console.log("sksks", morphOnHoldData(onHoldData)[ind]);
                                        console.log("liessss", OnHoldDataToArr(onHoldData));
                                        console.log("currData", currData);

                                        updateTemp(currData, uniqueOutbound);
                                    },
                                },
                            ]}
                            emptyState={{
                                message: "No booking found on hold",
                                button: {
                                    label: "Start New Trip",
                                    action: directToTrips,
                                },
                            }}
                        />
                    </div>
                </div>

                <div className="search-history">
                    <p className="first mb-3">Search History</p>
                    {/* <div className="search-history-heading">
                        <p>Name</p>
                        <p>Date</p>
                    </div> */}
                    <div className="search-history-body-wrapper">
                        {/* {searchHistory?.length === 0 ? (
                            <div className="on-hold-body-empty">
                                <p className="color-text">
                                    All your search history will be shown here
                                </p>
                            </div>
                        ) : (
                            searchHistory?.map((data) => {
                                return <SearchHistory data={data} />;
                            })
                        )} */}
                        <MUITable
                            headers={[
                                {label: "Name", key: "name"},
                                {label: "Travel Date", key: "date"},
                            ]}
                            bodyData={morphSearchHistory(searchHistory?.slice(-5))}
                            stripped={false}
                            showPagination={false}
                            emptyState={{
                                message: "All your search history will be shown here",
                            }}
                        />
                    </div>
                </div>
            </div>
            <a href="https://wa.me/2348121463765?text=Hello%20there!" target="_blank">
                <div
                    className="chatMobile"
                    onClick={() => {
                        window.fcWidget.open();
                        window.fcWidget.show();
                    }}
                >
                    <BsChatRightFill size={24}/>
                </div>
            </a>
            <div className="fundMobile">
                <BsFillPlusCircleFill
                    size={65}
                    color="#243256"
                    onClick={() => setOpenFundPop(!openFundPop)}
                />
                <div
                    className={`fundMobile__table ${
                        openFundPop && "fundMobile__table--open"
                    }`}
                >
                    <div className="fund-head" onClick={() => setOpenFundAccount(true)}>
                        <img src={topup} alt="top"/>
                        <span>Fund your account</span>
                    </div>
                    <div className="fund-body">
                        <div className="fund-line">
                            <div className="img-container">
                                <img src={cash} alt="flight"/>
                                <p>Credit Balance</p>
                            </div>
                            <p>
                                ₦{prettifyMoney(creditBalance)}
                                {/* N1,000,0000 */}
                            </p>
                        </div>
                        <div className="fund-line">
                            <div className="img-container">
                                <img src={wallet} alt="flight"/>
                                <p>Wallet Balance</p>
                            </div>
                            <p>₦{prettifyMoney(wallettBalance)}</p>
                        </div>
                        <div className="fund-line">
                            <div className="img-container">
                                <img src={total} alt="flight"/>
                                <p>Total Balance</p>
                            </div>
                            <p>₦{prettifyMoney(creditBalance + wallettBalance)}</p>
                        </div>
                        <div
                            className="fund-line fund-line--active"
                            onClick={() => setOpenConvertPoints(true)}
                        >
                            <div className="img-container">
                                <img src={bonus} alt="flight"/>
                                <div>
                                    Bonus Points
                                    <span
                                        style={{
                                            color: "rgba(101, 100, 95, 0.44)",
                                            cursor: "pointer",
                                            fontSize: "13px",
                                            marginLeft: "0.5rem",
                                        }}
                                        className="q-mark"
                                    >
										?
										<CustomTooltip>
											Bonus points can be converted to cash when it reaches
											1500. 10 points equals 100 naira
										</CustomTooltip>
									</span>
                                </div>
                            </div>
                            <p>{pointBalance}</p>
                        </div>
                    </div>
                </div>
            </div>
            <MUIModal open={openHeadsup} handleClose={() => setOpenHeadsup(false)}>
                <HeadsUpModal
                    handleClose={() => setOpenHeadsup(false)}
                    proceedAction={() => {
                        updateBigFlightData(tempBigFlightData);
                        // setTimeout(() => {
                        navigate("/trips/on-hold-summary");
                        // }, 3000);
                    }}
                />
            </MUIModal>
            <MUIModal
                open={openConvertPoints}
                handleClose={() => setOpenConvertPoints(false)}
            >
                <ConvertPointsModal
                    handleClose={() => setOpenConvertPoints(false)}
                    refetch={refetch}
                    setRefetch={setRefetch}
                />
            </MUIModal>
            <MUIModal
                open={openFundAccount}
                handleClose={() => setOpenFundAccount(false)}
            >
                <FundAccountModal
                    email={userData?.email}
                    handleClose={() => setOpenFundAccount(false)}
                    type="fund_wallet"
                    refetch={refetch}
                    setRefetch={setRefetch}
                    balanceLoading={balanceLoading}
                />
            </MUIModal>
            <MUIModal
                open={openReferralModal}
                handleClose={() => setOpenReferralModal(false)}
            >
                <ReferralModal/>
            </MUIModal>
            <MUIModal
                open={openCreditModal}
                handleClose={() => setOpenCreditModal(false)}
            >
                <CreditModal handleClose={() => setOpenCreditModal(false)}/>
            </MUIModal>
        </div>
    );
}

export const OnHold = ({data, position}: { data: any; position: number }) => {
    let {flight_id, passenger, destination, departure, departure_date, status} =
        data;
    let diff_date = new Date(departure_date).valueOf() - new Date().valueOf();
    let hours = Math.floor(diff_date / (1000 * 60 * 60 * 24));
    // console.log(hours);
    return (
        <div className={`on-hold-body ${position % 2 === 0 ? "" : "grey"}`}>
            <p>{flight_id.slice(0, 6)}</p>
            <p>{passenger}</p>
            <p>
                {departure} - {destination}
            </p>
            <p>{hours >= 1 ? hours + " " + "Days" : "0 Days"}</p>
            <p className="status">{hours >= 1 ? status : <span>Expired</span>}</p>
        </div>
    );
};

export const SearchHistory = ({data}: { data: any }) => {
    let {from_location, to_location, departure_date, return_date} = data;
    let month = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    return (
        <div className="search-history-body">
            <p>
                {" "}
                {from_location} - {to_location}
            </p>
            <p>
                {new Date(departure_date).getDate() +
                    " " +
                    month[new Date(departure_date).getMonth()]}
                -{" "}
                {new Date(return_date).getDate() +
                    " " +
                    month[new Date(return_date).getMonth()]}
            </p>
        </div>
    );
};

const MiddleCard = ({
                        middleItem,
                        item,
                    }: {
    item: any;
    middleItem: { icon: any; label: any; count: any; amount: any };
}) => (
    <div className="middle-card">
        <div className="left">
            <img src={middleItem.icon} alt="plane"/>
            <p>{middleItem.label}</p>
        </div>
        <div className="right">
            <p className="count">{item[middleItem.count]}</p>
            <p className="amount">₦ {prettifyMoney(item[middleItem.amount])}</p>
        </div>
    </div>
);
