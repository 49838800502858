import { useState } from "react";
import { generateMonthList } from "../../helpers/helperFuncs";
import CustomDropdown from "../CustomDropdown";
import "./style.scss";

interface DateDropDownProps {
	dateValue: string;
	updateDateValue: (val: string) => void;
	variant?: "all" | "past" | "future";
}

const DateDropDown = ({
	dateValue,
	updateDateValue,
	variant = "all",
}: DateDropDownProps) => {
	const [active, setActive] = useState<number>(0); //this state moves the cursor once you select a section (e.g. moves the cursor to month once you select day.)

	return (
		<div className="DateDropDown">
			<CustomDropdown
				onSelect={(val: any) => {
					if(!dateValue) return
					const newDateObj = new Date(dateValue);
					newDateObj.setDate(val);

					updateDateValue(newDateObj.toISOString());
					setActive(2);
				}}
				placeholder="Day"
				defaultValue={dateValue ? new Date(dateValue).getDate().toString() : ""}
				optionsList={Array(31)
					.fill(0)
					.map((item: any, ind: number) => ({
						label: (ind + 1).toString(),
						value: (ind + 1).toString(),
					}))}
				ind={1}
				active={active === 1}
			/>
			<CustomDropdown
				onSelect={(val: any) => {
					if(!dateValue) return
					const newDateObj = new Date(dateValue);
					newDateObj.setMonth(
						generateMonthList(2023).findIndex((item) => item === val)
					);

					updateDateValue(newDateObj.toISOString());
					setActive(3);
				}}
				placeholder="Month"
				defaultValue={
					dateValue
						? generateMonthList(2023)[new Date(dateValue).getMonth()]
						: ""
				}
				optionsList={generateMonthList(2023).map((item: any, ind: number) => ({
					label: item,
					value: item,
				}))}
				ind={1}
				active={active === 2}
			/>
			<CustomDropdown
				onSelect={(val: any) => {
					console.log("val", val);
					const newDateObj = new Date(dateValue);
					newDateObj.setFullYear(val);

					updateDateValue(newDateObj.toISOString());
					setActive(0);
				}}
				placeholder="Year"
				defaultValue={
					dateValue ? new Date(dateValue).getFullYear().toString() : ""
				}
				optionsList={Array(300)
					.fill(0)
					.map((item: any, ind: number) => ({
						label: (variant === "past"
							? 2023 - ind
							: variant === "future"
							? ind + 2023
							: ind + 2023 - 150
						).toString(),
						value: (variant === "past"
							? 2023 - ind
							: variant === "future"
							? ind + 2023
							: ind + 2023 - 150
						).toString(),
					}))}
				ind={1}
				active={active === 3}
			/>
		</div>
	);
};

export default DateDropDown;
